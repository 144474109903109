.Certificate{
    border: 1px solid #e5e5e5;
    background-color: #fff;
}
.Certificate_title{
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}
.uplaod_image{
    border: none;
    outline: none;
}
 .upload_size{
    font-size: 14px;
    color: #818181;
}

#messageAlertCertificate{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}