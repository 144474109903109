.Exprience{
    border: 1px solid #e5e5e5;
}
.Exprience_title{
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}
.current_title{
    font-size: 13px;
    font-weight: 500;
}
.past_title{
font-size: 13px;
font-weight: 500;
padding-bottom: 5px;
}

.Exprience .input-group-workExp{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.Exprience .input-group-workExp input{
    height: 40px;
    width: 49%;
    border: 1px solid #dee2e6;
    font-family: Roboto,sans-serif;
    font-size: .75rem;
    font-weight: 400;
    padding: 0px 12px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


@media only screen and (max-width: 600px) {
    .save_change{
        margin-left: 0px!important;
    }
}

#messageAlertWorkExp{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}