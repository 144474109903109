.change__password h3{
    font-size: 24px;
    font-weight: 500;
    font-family: sans-serif;
    padding-top: 40px;
}
.change__password input{
    height: 38px;
    width: 80%;
    margin: auto;
    border: none;
    border-bottom: 1px solid #8d8d8d;
    outline: none;
    font-size: 12px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.change__password input:focus{
    border-bottom: 1px solid #389af7;
}

.verify{
    margin-top: 20px;   
    color: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    float: left;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(72, 119, 221) 0%, rgba(20,84,194,1) 62%, rgb(6, 78, 129) 100%, rgba(0,212,255,1) 100%);  transition: all 0.3s ease-in-out;
    padding: 7px 25px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.changes_btn{
    margin-top: 20px;
    margin-bottom: 30px;   
    color: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    float: left;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(72, 119, 221) 0%, rgba(20,84,194,1) 62%, rgb(6, 78, 129) 100%, rgba(0,212,255,1) 100%);  transition: all 0.3s ease-in-out;
    padding: 8px 30px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
