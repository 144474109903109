.video_title{
    padding: 10px;
}

.video-title{
    font-size: 18px;
    font-weight: 500;
}

.category{
   border-right: 3px solid #f3f3f3;
   height: 400px;
   overflow-y: scroll;
   transition: all ease-in 3s!important;
   -webkit-transition: all ease-in 3s!important;
   -moz-transition: all ease-in 3s!important;
   -ms-transition: all ease-in 3s!important;
   -o-transition: all ease-in 3s!important;
}

.category::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.category::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
.category::-webkit-scrollbar-thumb {
    background: #888;
  }


.category ul{
    display: block;
}
.category ul li{
    text-decoration: none;
    list-style: none;
    padding: 3px;
    /* padding-left: 20px; */
    background-color: #fff;
    margin-top: 7px;
    margin-left: -15px;
    border-radius: 3px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
}

.category ul li i{
    float: right;
    /* font-weight: 500; */
    padding-top: 5px;
    color: #000;
    padding-right: 10px;
}

.category ul li:hover{
    background-color: #e1e1e1;
}


.learningCategoryButton{
    margin: 0!important;
    padding: 0!important;
    border: none!important;
    width: 100%!important;
    background-color: transparent;
    padding: 5px!important;
    padding-left: 10px!important;
    text-align: left;
}

.learnvideo{
    height: 500px;
    overflow-y: scroll;
}

.learnvideo::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
.learnvideo::-webkit-scrollbar-track {
    background: #f1f1f1;
 }
    
    /* Handle */
.learnvideo::-webkit-scrollbar-thumb {
    background: #888;
}


.back_btn{
    border: none;
    outline: none;
   background-color: #fff;
}
.back_btn i{
    font-size: 16px;
    color: rgb(19, 18, 18);
}

.spinner-wrapper{
    z-index: 10001!important;
}

.noRecordsFound{
    box-shadow: none!important;
}
.noRecordsFound:hover{
    background-color: transparent!important;   
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset;
    position: unset;
    width: calc(100% - 270px - 4rem);
    margin: auto;
    margin-top: 50px;
}
.spinner-wrapper .donout {
    margin: auto;
}