.resume_card .profile {
  position: relative;
  width: 100%;
  height: 320px
}

.resume_card .profile img{
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
}

.resume_card .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.resume_card .profile:hover .image {
    background-color: rgba(0, 0, 0, 0.014);
    opacity: 0.2;
    padding-bottom: 3px;
}

.resume_card .profile:hover .middle {
  opacity: 1;
}

.resume_card .text {
  background-color: #f8a100;
  color: white;
  font-size: 14px;
  padding: 12px;
}

.resume_card .text {
  z-index: 1;
  position: relative;
  font-family: inherit;
  color: white;
  outline: none;
  border: none;
  border-radius: 2px;
  background-color: #f8a100;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.resume_card .text:hover {
  cursor: pointer;
}

.resume_card .text::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid #f0aa28;
  transform-origin: center;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.resume_card .text:hover::before {
  transition: all 0.95s ease-in-out;
  transform-origin: center;
  transform: scale(1.75);
  opacity: 0;
  -webkit-transform: scale(1.75);
  -moz-transform: scale(1.75);
  -ms-transform: scale(1.75);
  -o-transform: scale(1.75);
  -webkit-transition: all 0.95s ease-in-out;
  -moz-transition: all 0.95s ease-in-out;
  -ms-transition: all 0.95s ease-in-out;
  -o-transition: all 0.95s ease-in-out;
}


.resume_card .middle a{
    text-decoration: none;
    color: #fff;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #25375c;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}