#downloadCV:hover{
  color: #fff!important;
}
/* dark theme css end */

/* light theme css start */


/* html {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: scroll;
} */
/* body {
  background-color: #f2f7f9;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 15px;
  font-family: poppins, Helvetica, sans-serif;
  line-height: 1.65em;
  overflow: hidden;
  color: #555;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
  background-size: cover;
} */
#lightcv h1,
#lightcv h2,
#lightcv h3,
#lightcv h4,
#lightcv h5,
#lightcv h6 {
  color: #222!important;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 600;
}
#lightcv h1 {
  font-size: 32px;
}
#lightcv h2 {
  font-size: 27px;
}
#lightcv h3 {
  font-size: 21px;
}
#lightcv h4 {
  font-size: 18px;
}
#lightcv h5 {
  font-size: 16px;
}
#lightcv h6 {
  font-size: 14px;
}
#lightcv a {
  color: #09c;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv p {
  margin-bottom: 10px;
}
#lightcv .clearfix:before,
#lightcv .clearfix:after {
  content: " ";
  display: table;
}
#lightcv .clearfix:after {
  clear: both;
}

#lightcv .page {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 100px;
  overflow: hidden;
}

@media only screen and (max-width: 1024px){
  #lightcv  .page-content {
        padding: 0;
        margin: 0;
        border-radius: 0;
        min-height: 100vh!important;
    }
}
    


#lightcv .page-content {
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-height: 80vh;
  margin: 10vh auto;
  padding: 0;
  background-color: #04b4e0;
  -webkit-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
#lightcv .btn-primary,
#lightcv .btn-secondary,
#lightcv  button,
#lightcv input[type="button"],
#lightcv input[type="submit"],
#lightcv .wp-block-button .wp-block-button__link {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #04b4e0;
  color: #222;
  text-shadow: none;
  background-color: #fff;
  border-radius: 30px;
  font-family: poppins, Helvetica, sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.22);
}
#lightcv .btn-primary:last-child,
#lightcv .btn-secondary:last-child,
button:last-child,
#lightcv input[type="button"]:last-child,
#lightcv input[type="submit"]:last-child,
.wp-block-button .wp-block-button__link:last-child {
  margin-right: 0;
}
#lightcv .btn-primary:hover,
#lightcv .btn-primary:focus,
#lightcv button:hover,
#lightcv button:focus,
#lightcv input[type="button"]:hover,
#lightcv input[type="button"]:focus,
#lightcv input[type="submit"]:hover,
#lightcv input[type="submit"]:focus {
  background-color: #04b4e0;
  color: #fff;
  border: 2px solid #04b4e0;
}
#lightcv .btn-secondary {
  border-color: #d5d5d5;
  background-color: #fff;
  box-shadow: 0 10px 10px -8px #d5d5d5;
  color: #666;
}
#lightcv .btn-secondary:hover,
#lightcv .btn-secondary:focus {
  color: #333;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
}
#lightcv .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#lightcv .no-js .preloader,
#lightcv .no-js .preloader-portfolio {
  display: none;
}
#lightcv .preloader,
#lightcv .preloader-portfolio {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
}
#lightcv .preloader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: inherit;
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
  transform: translate3d(-50%, -50%, 0);
}
#lightcv .preloader-spinner {
  width: 52px;
  height: 52px;
  margin: 100px auto;
  background-color: #04b4e0;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
}
@media only screen and (min-width: 1025px) {
  #lightcv  .header {
    display: inline-block;
    float: left;
    width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: 80vh;
    min-height: inherit;
    text-align: center;
    padding: 40px 30px 45px;
    overflow: auto;
  }
  /* .header-titles {
  } */
  #lightcv ul.main-menu {
    position: absolute;
    padding: 15px 0;
    width: 70px;
    right: -90px;
    background-color: #fff;
    border-radius: 35px;
    z-index: 999;
    list-style: none;
    top: 0;
    -webkit-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
  }
  #lightcv ul.main-menu a {
    display: block;
    position: relative;
    color: #b5b6b7;
    padding: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  #lightcv ul.main-menu a.active,
  #lightcv ul.main-menu a:focus,
  #lightcv ul.main-menu a:hover {
    color: #04b4e0!important;
  }
  #lightcv ul.main-menu a.active .lnr,
  #lightcv ul.main-menu a:focus .lnr,
  #lightcv ul.main-menu a:hover .lnr {
    color: #04b4e0!important;
  }
  #lightcv ul.main-menu .menu-icon {
    display: block;
    font-size: 30px;
  }
  #lightcv ul.main-menu .link-text {
    position: absolute;
    width: auto;
    visibility: hidden;
    opacity: 0;
    color: #fff;
    padding: 2px 10px;
    background-color: #04b4e0;
    white-space: nowrap;
    right: 0;
    top: -50%;
    margin-top: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.22);
  }
  #lightcv ul.main-menu a:hover .link-text {
    right: 100%;
    visibility: visible;
    opacity: 1;
  }
}
#lightcv .lmpixels-arrows-nav {
  position: absolute;
  padding: 10px 0;
  width: 60px;
  right: -80px;
  background-color: #fff;
  border-radius: 35px;
  z-index: 98;
  list-style: none;
  bottom: 0;
  -webkit-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.15);
}
#lightcv .lmpixels-arrows-nav div {
  display: block;
  position: relative;
  text-align: center;
  color: #b5b6b7;
  padding: 7px;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .lmpixels-arrows-nav div:hover {
  color: #04b4e0;
}
#lightcv .header-photo {
  position: relative;
  width: 180px;
  margin: 0 auto 30px;
  z-index: 1;
}
#lightcv .header-photo img {
  max-width: 100%;
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: 300px;
}
#lightcv .header-photo:after {
  position: absolute;
  opacity: 0.3;
  top: 7%;
  left: 7%;
  border-radius: 300px;
  content: "";
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  background-image: -webkit-repeating-radial-gradient(
    center center,
    #fff,
    #fff 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    #fff,
    #fff 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    #fff,
    #fff 1px,
    transparent 0px,
    transparent 100%
  );
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  z-index: -1;
}
#lightcv .header-titles h2 {
  font-size: 36px;
  font-weight: 600;
  color: #fff!important;
  margin: 5px 0 7px;
  line-height: 1.2em;
}
#lightcv .header-titles h4 {
  font-size: 18px;
  font-weight: 300;
  color: #fff!important;
  margin: 5px 0;
  line-height: 1.2em;
}
#lightcv .social-links {
  margin: 20px 0;
}
#lightcv .social-links ul {
  list-style: none;
  padding: 0;
}
#lightcv .social-links ul li {
  display: inline-block;
}
#lightcv .social-links ul li a {
  color: #fff;
  border-radius: 20px;
  font-size: 18px;
  padding: 0;
  height: 30px;
  width: 30px;
  display: block;
  line-height: 30px;
  text-align: center;
  opacity: 0.9;
}


#lightcv .social-links ul li a:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
}
#lightcv .header-buttons {
  margin-top: 50px;
}
#lightcv .Downloadcv{
    padding: 10px 40px;
    border-radius: 100px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    background-color: #5ab5e1;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    font-family: poppins, Helvetica, sans-serif;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

#lightcv .Downloadcv:hover{
  background-color: #fff;
  border: 2px solid #fff;
  color: #5ab5e1;
}



/* #lightcv .header-buttons .btn-primary {
  background-color: #fff!important;
  color: #fff!important;
  border-color: #fff!important;
}
#lightcv .header-buttons .btn-primary:hover {
  background-color: #fff!important;
  color: #04b4e0!important;
  border-color: #fff!important;
} */
#lightcv .header .copyrights {
  color: #fff;
  width: 380px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 10px 15px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
}
#lightcv .content-area {
  position: absolute;
  right: 0;
  background-color: transparent;
  height: 100%;
  width: 100%;
  max-width: calc(100% - 380px);
}
#lightcv .animated-sections {
  position: relative;
  height: 100%;
  -webkit-perspective: 1500px;
  -moz-perspective: 1500px;
  perspective: 1500px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
#lightcv .animated-section {
  position: absolute;
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  padding: 60px;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
  background-size: cover;
}
#lightcv .single-page-content {
  position: absolute;
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  padding: 60px;
  overflow: auto;
}
#lightcv .section-active,
#lightcv .no-js .animated-section {
  opacity: 1;
  overflow: auto;
  visibility: visible;
  z-index: 99;
}
#lightcv .ps > .ps__scrollbar-y-rail {
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
}
#lightcv .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: #b5b6b7;
}
#lightcv .ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  background-color: #04b4e0;
}
#lightcv .ps > .ps__scrollbar-y-rail {
  width: 12px;
}
#lightcv .ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y,
#lightcv .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
#lightcv .ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
#lightcv.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  width: 8px;
}
#lightcv .page-title {
  display: inline-block;
  position: relative;
  padding-right: 25px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
#lightcv .page-title h2 {
  position: relative;
  font-size: 32px;
  z-index: 1;
}
#lightcv .page-title h2 span {
  color: #04b4e0!important;
}
#lightcv .block-title h3 span {
  color: #04b4e0!important;
}
#lightcv .page-title:after {
  position: absolute;
  opacity: 0.45;
  top: 10px;
  right: 0;
  content: "";
  height: 30px;
  width: 50px;
  background-image: -webkit-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  z-index: 0;
}
#lightcv .form-group {
  position: relative;
  margin: 0 0 21.5px;
}
#lightcv .form-control,
#lightcv .form-control:focus {
  height: 42px;
}
#lightcv .form-control,
#lightcv .form-control:focus,
#lightcv .has-error .form-control,
#lightcv .has-error .form-control:focus,
#lightcv input[type="search"],
#lightcv input[type="password"],
#lightcv input[type="text"] {
  position: relative;
  border: 2px solid #d5d6d7;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  background: 0 0;
  background-color: transparent;
  text-align: left;
  color: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: poppins, Helvetica, sans-serif;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.1);
}
#lightcv textarea.form-control,
#lightcv textarea.form-control:focus {
  height: auto;
}
#lightcv .form-control ~ .form-control-border {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  border-radius: 5px;
  top: 0;
  opacity: 0;
  background: 0 0;
  border: 2px solid #04b4e0;
  border-right-width: 0;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .form-control:focus ~ .form-control-border {
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  opacity: 1;
  border-right-width: 2px;
}
#lightcv .has-error .form-control ~ .form-control-border {
  border-color: #ff4c4c;
}
#lightcv .form-control::-moz-placeholder {
  color: #adadac;
}
#lightcv .form-control:-ms-input-placeholder {
  color: #adadac;
}
#lightcv .form-control::-webkit-input-placeholder {
  color: #adadac;
}
#lightcv .form-group .help-block {
  position: absolute;
  display: inline-block;
  padding: 0 5px;
  font-size: 0.93em;
  line-height: 1.75em;
  margin: -2px 0 0 10px;
  color: #fff;
  background: #ff4e4e;
}
#lightcv .form-group .help-block:after {
  content: " ";
  position: absolute;
  left: 5px;
  bottom: 100%;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ff4e4e;
  border-right: 10px solid transparent;
}
#lightcv .form-group .help-block:empty {
  display: none;
}
#lightcv .form-group-with-icon i {
  position: absolute;
  font-size: 16px;
  top: 13px;
  right: 13px;
  color: #d3d3d3;
}
#lightcv .form-group-with-icon.form-group-focus i {
  color: #04b4e0;
}
#lightcv .form-group.form-group-w-checkbox {
  padding-top: 0;
  padding-bottom: 8px;
  margin-bottom: 17px;
}
#lightcv .form-group-with-icon.form-group-w-checkbox {
  padding-top: 8px;
  padding-left: 52px;
  margin-bottom: 25px;
}
#lightcv .form-group .form-control-checkbox + label,
#lightcv .form-group.form-group-focus .form-control-checkbox + label {
  position: relative;
  display: inline;
  left: 0;
  top: 0;
  font-size: 1em;
  opacity: 1;
}
#lightcv .form-group .form-control-checkbox,
#lightcv .form-group.form-group-focus .form-control-checkbox {
  display: inline-block;
  width: auto;
  height: auto;
  top: 2px;
}
#lightcv .form-group.form-group-w-checkbox .help-block {
  top: 100%;
  left: 0;
  margin-left: 0;
}
#lightcv .form-group.form-group-with-icon.form-group-w-checkbox .help-block {
  left: 52px;
}
#lightcv .form-group label {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: -1;
  color: #a5a6a7;
  transition: 0.3s;
}
#lightcv .form-group .form-control:focus ~ label,
#lightcv .form-group.form-group-focus .form-control ~ label {
  color: #04b4e0;
  top: -20px;
  left: 0;
  z-index: 1;
  font-size: 13px;
}
#lightcv .form-control,
#lightcv .form-control:focus,
#lightcv .has-error .form-control,
#lightcv .has-error .form-control:focus,
#lightcv input[type="search"],
#lightcv input[type="password"],
#lightcv input[type="text"],
#lightcv .header-search input.form-control {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}
#lightcv .block-title {
  display: inline-block;
  position: relative;
  padding-right: 12px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
#lightcv .block-title h3 {
  position: relative;
  font-size: 21px;
  z-index: 1;
}
#lightcv .block-title h3 span {
  color: #04b4e0;
}
#lightcv .block-title:after {
  position: absolute;
  opacity: 0.45;
  top: 10px;
  right: 0;
  content: "";
  height: 20px;
  width: 30px;
  background-image: -webkit-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    #04b4e0,
    #04b4e0 1px,
    transparent 0px,
    transparent 100%
  );
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  z-index: 0;
}
#lightcv .white-space-10 {
  padding-bottom: 10px;
}
#lightcv .white-space-20 {
  padding-bottom: 20px;
}
#lightcv .white-space-30 {
  padding-bottom: 30px;
}
#lightcv .white-space-40 {
  padding-bottom: 40px;
}
#lightcv .white-space-50 {
  padding-bottom: 50px;
}
#lightcv .white-space-60 {
  padding-bottom: 60px;
}
#lightcv .white-space-70 {
  padding-bottom: 70px;
}
#lightcv .info-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#lightcv .info-list ul li {
  margin-bottom: 10px;
}
#lightcv .info-list .title {
  color: #04b4e0!important;
  margin-right: 5px;
  font-weight: 600;
}
#lightcv .info-block-w-icon {
  margin-bottom: 25px;
}
.info-block-w-icon .ci-text h4 {
  margin: 5px 0;
}
#lightcv .info-block-w-icon .ci-text p {
  font-size: 0.95em;
}
#lightcv .info-block-w-icon .ci-icon {
  display: table-cell;
  width: 54px;
  padding-right: 25px;
}
#lightcv .info-block-w-icon i {
  position: relative;
  font-size: 42px;
  color: #04b4e0;
  opacity: 0.7;
}
#lightcv .lm-info-block {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 0 15px;
  background-color: #fcfcfc;
  padding: 20px 10px;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .lm-info-block i {
  position: relative;
  font-size: 33px;
  color: #04b4e0;
  z-index: 1;
}
#lightcv .lm-info-block h4 {
  font-size: 15px;
  margin-top: 8px;
}
#lightcv .lm-info-block .lm-info-block-value {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  display: block;
  margin: 10px 0;
  color: #04b4e0;
}
#lightcv .lm-info-block .lm-info-block-value:empty {
  margin: 0;
}
#lightcv .testimonial {
  text-align: center;
  border: 2px solid #e5e6e7;
  border-radius: 20px;
  margin: 45px 2px 10px;
  padding: 0 25px 15px;
}
#lightcv .testimonial img {
  max-width: 90px;
  max-height: 90px;
  margin: -45px auto 20px;
  border-radius: 90px;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.22);
}
#lightcv .testimonial .text {
  text-align: left;
  font-style: italic;
}
#lightcv .testimonial .author-info {
  position: relative;
  text-align: left;
  margin-top: 20px;
}
#lightcv .testimonial .author-info .icon {
  content: "";
  position: absolute;
  font-size: 30px;
  right: 0;
  top: 7px;
  color: #04b4e0;
  opacity: 0.5;
}
#lightcv .testimonial .author-info .author {
  margin: 0;
  font-size: 15px;
}
#lightcv .testimonial .author-info .company {
  color: #a5a6a7;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}
#lightcv .clients.owl-carousel .client-block {
  padding: 15px;
  margin-bottom: 15px;
}
#lightcv .client-block {
  text-align: center;
  padding: 5px 0;
  margin-bottom: 30px;
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .testimonials.owl-carousel .owl-nav,
#lightcv .clients.owl-carousel .owl-nav {
  position: absolute;
  text-align: right;
  right: 0;
  top: -52px;
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-prev,
#lightcv .testimonials.owl-carousel .owl-nav .owl-next,
#lightcv .clients.owl-carousel .owl-nav .owl-prev,
#lightcv .clients.owl-carousel .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  font-family: "font awesome 5 free";
  font-size: 11px;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  color: #666;
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-prev,
#lightcv .clients.owl-carousel .owl-nav .owl-prev {
  margin-right: 3px;
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-prev:before,
#lightcv .testimonials.owl-carousel .owl-nav .owl-next:before,
#lightcv .clients.owl-carousel .owl-nav .owl-prev:before,
#lightcv .clients.owl-carousel .owl-nav .owl-next:before {
  position: relative;
  margin: 2px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  display: block;
  cursor: pointer;
  color: inherit;
  z-index: 1;
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-prev:before,
#lightcv .clients.owl-carousel .owl-nav .owl-prev:before {
  content: "\f053";
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-next:before,
#lightcv .clients.owl-carousel .owl-nav .owl-next:before {
  content: "\f054";
}
#lightcv .testimonials.owl-carousel .owl-nav .owl-prev:hover,
#lightcv .testimonials.owl-carousel .owl-nav .owl-next:hover,
#lightcv .clients.owl-carousel .owl-nav .owl-prev:hover,
#lightcv .clients.owl-carousel .owl-nav .owl-next:hover {
  background-color: #04b4e0;
  border-color: #04b4e0;
  color: #fff;
}
#lightcv .timeline .timeline-item{
  border: none!important;
}
#lightcv .timeline .timeline-item:before {
  background: none!important;
}
#lightcv .timeline-second-style .timeline-item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 15px;
}
#lightcv .timeline-second-style .timeline-item:last-child {
  padding-bottom: 0;
}
#lightcv .timeline-second-style .left-part {
  width: 30%;
  display: table-cell;
  padding-right: 25px;
  min-height: 100%;
  text-align: right;
  vertical-align: top;
}
#lightcv .timeline-second-style .right-part {
  width: 70%;
  display: table-cell;
  padding-left: 25px;
  padding-right: 15px;
  vertical-align: top;
}
#lightcv .timeline-second-style .right-part p,
#lightcv .timeline-second-style .right-part p > * {
  font-size: 0.92em;
}
#lightcv .timeline-second-style .divider {
  position: absolute;
  top: 0;
  left: 30%;
  bottom: 0;
  width: 1px;
  background-color: #eee;
}
#lightcv .timeline-second-style .divider:before {
  content: "";
  display: block;
  position: absolute;
  margin-top: 4px;
  width: 17px;
  height: 17px;
  position: absolute;
  margin-left: -8px;
  border-radius: 10px;
  background-color: #04b4e0;
  opacity: 0.25;
  z-index: 0;
}
#lightcv .timeline-second-style .divider:after {
  content: "";
  display: block;
  position: absolute;
  margin-top: 8px;
  width: 9px;
  height: 9px;
  margin-left: -4px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #04b4e0;
  z-index: 1;
}
#lightcv .timeline-second-style .item-title {
  font-size: 16px;
  margin-bottom: 3px;
}
#lightcv .timeline-second-style .item-period {
  margin: 3px 0;
  font-size: 14px;
  line-height: 1.4em;
}
#lightcv .timeline-second-style .item-company {
  display: block;
  margin: 0 0 4px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.45em;
  color: #a5a6a7;
}
#lightcv .timeline-second-style .timeline-item:last-child .right-part {
  padding-bottom: 0;
}
#lightcv .timeline-second-style .item-logo {
  display: block;
  margin-bottom: 10px;
}
#lightcv .timeline-second-style .item-logo img {
  max-height: 40px;
}
#lightcv .skills-info.skills-second-style {
  margin-bottom: 30px;
}
#lightcv .skills-info.skills-second-style h4 {
  font-size: 13px;
  line-height: 1.1em;
  position: relative;
  float: left;
  margin: 0 0 4px;
}
#lightcv .skills-second-style .skill-container {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #04b4e0;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10px;
  margin-bottom: 8px;
  width: 100%;
}
#lightcv .skills-second-style .skill-container:before {
  content: "";
  position: absolute;
  background-color: #04b4e0;
}
#lightcv .skills-second-style .skill-value {
  font-size: 11px;
  line-height: 1.1em;
  position: relative;
  float: right;
  margin: 0 0 4px;
  color: #aaa;
}
#lightcv .skills-second-style .skill-percentage {
  background-color: #04b4e0;
  border: 2px solid #fff;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  height: 8px;
  padding: 0;
}
#lightcv .skills-second-style .skill-container.skill-1 .skill-percentage {
  width: 95%;
}
#lightcv .skills-second-style .skill-container.skill-2 .skill-percentage {
  width: 65%;
}
#lightcv .skills-second-style .skill-container.skill-3 .skill-percentage {
  width: 80%;
}
#lightcv .skills-second-style .skill-container.skill-4 .skill-percentage {
  width: 90%;
}
#lightcv .skills-second-style .skill-container.skill-5 .skill-percentage {
  width: 95%;
}
#lightcv .skills-second-style .skill-container.skill-6 .skill-percentage {
  width: 85%;
}
#lightcv .skills-second-style .skill-container.skill-7 .skill-percentage {
  width: 100%;
}
#lightcv .skills-second-style .skill-container.skill-8 .skill-percentage {
  width: 75%;
}
#lightcv .skills-second-style .skill-container.skill-9 .skill-percentage {
  width: 90%;
}
#lightcv .pt-page .skills-second-style .skill-percentage,
#lightcv .pt-page .skills-second-style .skill-value {
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  -ms-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}
#lightcv .js
  .subpages:not(.one-page-layout)
  .pt-page:not(.pt-page-current)
  .skills-second-style
  .skill-percentage {
  width: 0 !important;
}
#lightcv .js
  .subpages:not(.one-page-layout)
  .pt-page:not(.pt-page-current)
  .skills-second-style
  .skill-value {
  left: 26px !important;
}
#lightcv .knowledges {
  list-style: none;
}
#lightcv .knowledges li {
  display: inline-block;
  background-color: #04b4e0;
  border-radius: 3px;
  color: #fff;
  padding: 1px 10px;
  margin: 3px 2px;
  font-size: 13px;
}
#lightcv .certificate-item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 2px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 20px;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .certi-logo {
  display: table-cell;
  width: 120px;
  height: 100%;
  background-color: #f5f5f5;
  padding: 25px;
  vertical-align: middle;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
#lightcv .certi-logo img {
  display: block;
  max-width: 100%;
}
#lightcv .certi-content {
  display: table-cell;
  width: 100%;
  padding: 20px 25px;
  vertical-align: middle;
}
#lightcv .certi-title h4 {
  font-size: 16px;
  margin: 0 0 5px;
}
#lightcv .certi-id {
  font-size: 0.9em;
  color: #777;
}
#lightcv .certi-date {
  font-size: 0.7em;
  color: #777;
  opacity: 0.7;
}
#lightcv .controls.two-columns .left-column {
  width: 47%;
  float: left;
  margin-right: 3%;
}
#lightcv .controls.two-columns .right-column {
  width: 50%;
  float: right;
}
#lightcv .g-recaptcha {
  margin-bottom: 20px;
}
#lightcv .map {
  width: 100%;
  height: 140px;
  margin: 0 0 35px;
}
#lightcv .portfolio-filters {
  margin-bottom: 30px;
}
#lightcv .portfolio-filters li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
  opacity: 0.7;
}
#lightcv .portfolio-filters li.active {
  opacity: 1;
}
#lightcv .portfolio-filters li a {
  color: #666;
  cursor: pointer;
}
#lightcv .portfolio-grid {
  margin-left: -7px;
  margin-right: -7px;
}
#lightcv .portfolio-grid figure {
  width: 33.33333%;
  float: left;
  padding: 7px;
  position: relative;
  overflow: hidden;
}
#lightcv .portfolio-grid.one-column figure {
  width: 100%;
}
#lightcv .portfolio-grid.two-columns figure {
  width: 50%;
}
#lightcv .portfolio-grid.three-columns figure {
  width: 33.33333%;
}
#lightcv .portfolio-grid.four-columns figure {
  width: 25%;
}
#lightcv .portfolio-grid.five-columns figure {
  width: 20%;
}
#lightcv .portfolio-grid img {
  position: relative;
  display: block;
  width: 100%;
}
#lightcv .portfolio-grid figure a,
#lightcv .portfolio-rid figure img {
  display: block;
  position: relative;
}
#lightcv .portfolio-grid figure img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .portfolio-grid figure:hover img {
  transform: scale(1.1);
}
#lightcv .portfolio-grid figure a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
}
#lightcv .portfolio-item-img {
  position: relative;
  overflow: hidden;
}
#lightcv .portfolio-grid figure .portfolio-item-img:after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .portfolio-grid figure:hover .portfolio-item-img:after {
  opacity: 1;
}
#lightcv .portfolio-item-desc {
  margin-top: 12px;
}
#lightcv .portfolio-item-desc h4 {
  margin-bottom: 0;
}
#lightcv .portfolio-item-desc small {
  color: #888;
}
#lightcv .portfolio-grid figure i {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 16px;
  opacity: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #04b4e0;
  text-align: center;
  line-height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .portfolio-grid figure .name {
  display: block;
  padding: 15px 0;
  font-size: 16px;
  position: relative;
}
#lightcv .portfolio-grid figure .category {
  display: inline-block;
  padding: 3px 7px;
  background-color: #fff;
  color: #222;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  top: 15px;
  left: 15px;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .portfolio-grid figure:hover i,
#lightcv .portfolio-grid figure:hover .name,
#lightcv .portfolio-grid figure:hover .category {
  opacity: 1;
}
#lightcv .blog-masonry {
  margin: 0 -1em 1.8em;
}
#lightcv .blog-masonry.one-column .item {
  width: 100%;
  padding: 0 1em 2em;
}
#lightcv .blog-masonry.two-columns .item {
  width: 50%;
  float: left;
  padding: 0 1em 2em;
}
#lightcv .blog-masonry.three-columns .item {
  width: 33.333333%;
  float: left;
  padding: 0 1em 2em;
}
#lightcv .blog-card {
  display: block;
  position: relative;
  padding: 0;
  background: #fff;
}
#lightcv .blog-card .post-image {
  width: 100%;
  margin: 0;
}
#lightcv .blog-card .media-block {
  overflow: hidden;
}
#lightcv .blog-card .media-block img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#lightcv .blog-card .media-block:hover img {
  transform: scale(1.1);
}
#lightcv .blog-card .post-without-f-image {
  padding-bottom: 46.875%;
  width: 100%;
  background-color: #f5f5f5;
}
#lightcv .blog-card .post-info {
  padding: 1em 1.4em 1.4em;
  border: 1px solid #f5f5f5;
  border-top: 0;
}
#lightcv .blog-card .blog-item-title {
  margin: 5px 0 0;
}
#lightcv .blog-card .post-meta {
  padding: 1em;
  margin: 0;
}
#lightcv .blog-card .category a {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  padding: 3px 7px;
  line-height: 1.2em;
  font-weight: 600;
  font-size: 13px;
  color: #222;
  z-index: 3;
}
#lightcv .blog-card .post-date {
  display: inline-block;
  color: #888;
  font-size: 11px;
  line-height: 1.1em;
  font-weight: 300;
}
#lightcv .blog-card .media-block {
  position: relative;
}
#lightcv .sticky-badge {
  position: absolute;
  background-color: #fff;
  right: 10px;
  top: 10px;
  font-size: 12px;
  padding: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: #888;
  z-index: 3;
}
#lightcv .blog-card .media-block a .mask {
  position: absolute;
  background: #4f565e;
  background: rgba(0, 0, 0, 0.12);
  display: inline-block;
  font-family: linearicons-free;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .blog-card .media-block a .mask:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -22px 0 0 -22px;
  width: 44px;
  line-height: 44px;
  font-size: 44px;
  text-align: center;
  content: "\e828";
  color: #fff;
}
#lightcv .blog-card .media-block:hover a .mask {
  visibility: visible;
  opacity: 1;
}
#lightcv .blog-card ul.category li a {
  color: #888;
  font-size: 12px;
  font-weight: 300;
}
#lightcv .blog-card .post-meta .item,
#lightcv .post-meta .item a {
  color: #a6a6a6;
}
#lightcv .page-ajax-loaded {
  position: fixed;
  background-color: #fff;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}
#lightcv .page-ajax-loaded:empty {
  display: none !important;
}
#lightcv .ajax-page-content {
  height: 100%;
  overflow: auto;
  background-color: #fff;
}
#lightcv .ajax-page-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px 60px 50px;
}
#lightcv .ajax-page-nav {
  text-align: right;
}
#lightcv .ajax-page-nav > div.nav-item {
  position: relative;
  display: inline-block;
  margin: 0 3px;
}
#lightcv .ajax-page-nav > div.nav-item a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #888;
  background-color: #eee;
}
#lightcv .ajax-page-nav > div.nav-item a:hover {
  background-color: #04b4e0;
  color: #fff;
}
#lightcv .ajax-page-nav > div.nav-item a i {
  line-height: 40px;
  font-size: 22px;
}
#lightcv .ajax-page-title {
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: left;
}
#lightcv .ajax-page-title h1 {
  display: inline-block;
  margin: 0;
}
#lightcv .portfolio-block {
  padding-top: 30px;
}
#lightcv .ajax-page-page-content img {
  max-width: 100%;
}
#lightcv .portfolio-page-video,
#lightcv .portfolio-page-carousel,
#lightcv .portfolio-page-image {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
#lightcv .project-description {
  display: block;
  position: relative;
  padding: 20px 25px 25px;
  background-color: #fcfcfc;
}
#lightcv .ajax-page-wrapper .block-title h3 {
  font-size: 18px;
  margin-bottom: 15px;
}
#lightcv .project-general-info {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}
#lightcv .project-general-info li {
  margin: 0 0 10px;
}
#lightcv .project-general-info .fa {
  color: #04b4e0;
  margin-right: 5px;
}
#lightcv .project-general-info p {
  margin: 0;
}
#lightcv .share-buttons {
  display: block;
  margin: 25px 0 0;
}
#lightcv .share-buttons a {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  width: 28px;
  height: 28px;
  color: #888;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  text-align: center;
  border-radius: 0;
}
#lightcv .portfolio-page-content .share-buttons a {
  margin: 0 7px 0 0;
}
#lightcv .share-buttons a:hover {
  color: #fff;
  background-color: #04b4e0;
  border-color: #04b4e0;
}
#lightcv .share-buttons a:last-child {
  margin-right: 0;
  border-radius: 0;
}
#lightcv .share-buttons a i {
  font-size: 14px;
  line-height: 26px;
}
#lightcv .tags-block {
  margin: 25px 0 0;
}
#lightcv .tags-block .block-title {
  margin-bottom: 0;
}
#lightcv .tags {
  list-style: none;
  margin: 0;
  padding: 0;
}
#lightcv .tags > li {
  display: inline-block;
}
#lightcv .tags a {
  display: inline-block;
  font-size: 12px;
  line-height: 1.5em;
  color: #666;
  padding: 2px 7px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  margin: 3px 2px 2px;
}
#lightcv .tags a:first-child {
  margin-left: 0;
}
#lightcv .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
#lightcv .embed-responsive-16by9:before {
  padding-top: 56.25%;
}
#lightcv .embed-responsive-21by9:before {
  padding-top: 42.857143%;
}
#lightcv .embed-responsive-4by3:before {
  padding-top: 75%;
}
#lightcv .embed-responsive-1by1:before {
  padding-top: 100%;
}
#lightcv.embed-responsive::before {
  display: block;
  content: "";
}
#lightcv .embed-responsive .embed-responsive-item,
#lightcv .embed-responsive embed,
#lightcv .embed-responsive iframe,
#lightcv .embed-responsive object,
#lightcv .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
#lightcv .portfolio-page-carousel {
  overflow: hidden;
}
#lightcv .portfolio-page-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -20px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
#lightcv .portfolio-page-carousel .owl-nav [class*="owl-"] {
  color: #9e9e9e;
  margin: 0;
  padding: 4px 7px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: "font awesome 5 free";
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
#lightcv .portfolio-page-carousel .owl-nav [class*="owl-"]:before {
  display: block;
}
#lightcv .portfolio-page-carousel .owl-nav .owl-prev {
  position: absolute;
  margin-left: -50px;
  left: 0;
}
#lightcv .portfolio-page-carousel:hover .owl-nav .owl-prev {
  margin-left: 0;
}
#lightcv .portfolio-page-carousel .owl-nav .owl-next {
  position: absolute;
  margin-right: -50px;
  right: 0;
}
#lightcv .portfolio-page-carousel:hover .owl-nav .owl-next {
  margin-right: 0;
}
#lightcv .portfolio-page-carousel .owl-nav .owl-prev:before {
  content: "\f053";
}
#lightcv .portfolio-page-carousel .owl-nav .owl-next:before {
  content: "\f054";
}
#lightcv .portfolio-page-carousel .owl-dots {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 10px 5px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
#lightcv .portfolio-page-carousel .owl-dots .owl-dot {
  display: inline-block;
}
#lightcv .portfolio-page-carousel .owl-dots .owl-dot.active span,
#lightcv .portfolio-page-carousel .owl-dots .owl-dot:hover span {
  background: #9e9e9e;
}
#lightcv .portfolio-page-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px;
  background: #f5f5f5;
  border: 1px solid #9e9e9e;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
#lightcv .post-thumbnail {
  background: #b2b2b2;
  display: block;
  position: relative;
  width: 100%;
  z-index: 0;
}
#lightcv .single-page-content .post-content {
  position: relative;
  margin-top: -70px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  z-index: 1;
}
#lightcv .entry-header {
  position: relative;
  z-index: 1;
  padding: 5px 0 0;
}
#lightcv .entry-header .entry-meta {
  color: #9e9e9e;
  margin: 15px 0;
  font-size: 13px;
}
#lightcv .entry-meta a {
  color: #9e9e9e;
}
#lightcv .entry-title {
  font-size: 32px;
  line-height: 1.3;
  margin: 15px 0;
}
#lightcv .entry-meta.entry-meta-bottom {
  display: inline-block;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #eee;
  padding: 0 10px;
  margin: 30px 0 0;
}
#lightcv .entry-meta.entry-meta-bottom .date-author {
  display: inline-block;
  line-height: 28px;
  margin: 5px 0;
}
#lightcv .entry-meta.entry-meta-bottom .share-buttons {
  display: inline-block;
  margin: 5px 0;
}
#lightcv .entry-meta.entry-meta-bottom .share-buttons {
  display: inline-block;
  margin: 5px 0;
}
#lightcv .entry-meta-bottom .entry-share {
  float: right;
}
#lightcv .entry-meta.entry-meta-bottom .date-author > span:after {
  content: "";
  height: 14px;
  line-height: 1em;
  display: inline-block;
  margin: 0 6px 0 9px;
  background-color: #e5e5e5;
  width: 1px;
  top: 2px;
  position: relative;
}
#lightcv .entry-meta.entry-meta-bottom .date-author > span:last-child:after,
#lightcv .entry-meta.entry-meta-bottom .date-author > span:first-child:last-child:after {
  display: none;
}
#lightcv .post-tags {
  margin: 30px 0;
  text-align: left;
}
#lightcv blockquote {
  padding: 15px 20px;
  margin: 20px 0;
  font-size: 1em;
  border-left: 2px solid #d8d8d8;
  font-style: italic;
}
@media only screen and (max-width: 1280px) {
  #lightcv  .page {
    padding-left: 30px;
  }
  #lightcv .header {
    max-width: 330px;
  }
  #lightcv .content-area {
    max-width: calc(100% - 330px);
  }
}
@media only screen and (min-width: 1025px) {
  #lightcv .menu-toggle {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  #lightcv .header {
    position: absolute;
    width: 100%;
    max-width: 330px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #04b4e0;
    z-index: 99;
    padding: 30px 30px 10px;
    text-align: center;
    opacity: 1;
    visibility: visible;
    overflow: auto;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  #lightcv .header .copyrights {
    right: 0;
    width: 100%;
    position: relative;
    margin-top: 30px;
  }
  #lightcv .page {
    padding: 0;
  }
  #lightcv .page-content {
    padding: 0;
    margin: 0;
    border-radius: 0;
    min-height: 100%;
  }
  #lightcv .content-area {
    max-width: 100%;
  }
  #lightcv .animated-section,
  #lightcv .single-page-content {
    border-radius: 0;
    overflow: auto !important;
  }
  #lightcv .header-titles h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  #lightcv .header-titles h4 {
    font-size: 17px;
  }
  #lightcv .header-photo {
    max-width: 130px;
    margin-bottom: 25px;
  }
  #lightcv .mobile-menu-hide {
    width: 0;
    right: 0;
    margin-right: -100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  #lightcv ul.main-menu {
    margin: 30px 0;
  }
  #lightcv ul.main-menu a {
    display: block;
    color: #fff;
    padding: 12px 0;
    line-height: 21px;
    opacity: 0.7;
  }
  #lightcv ul.main-menu a.active {
    opacity: 1;
  }
  #lightcv ul.main-menu .menu-icon {
    display: none;
  }
  #lightcv ul.main-menu .link-text {
    font-size: 16px;
    line-height: 21px;
  }
  #lightcv .menu-toggle {
    position: absolute;
    display: block;
    width: 48px;
    height: 48px;
    line-height: 46px;
    text-align: center;
    background-color: #04b4e0;
    right: 10px;
    font-size: 19px;
    top: 10px;
    border-radius: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 900;
  }
  #lightcv .menu-toggle span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #fff;
    border-radius: 5px;
    opacity: 1;
    left: 25%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #lightcv .menu-toggle span:nth-child(1) {
    top: 16px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  #lightcv .menu-toggle span:nth-child(2) {
    top: 22px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  #lightcv .menu-toggle span:nth-child(3) {
    top: 28px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  #lightcv .menu-toggle.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 13px;
    left: 15px;
  }
  #lightcv .menu-toggle.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  #lightcv .menu-toggle.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 30px;
    left: 15px;
  }
  #lightcv .lmpixels-arrows-nav {
    background-color: rgba(255, 255, 255, 0.6);
    width: 48px;
    right: 10px;
    bottom: 10px;
    padding: 5px 0;
    border: 2px solid #04b4e0;
  }
  #lightcv .lmpixels-arrows-nav div {
    font-size: 20px;
  }
  #lightcv .single-page-content .post-content {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 768px) {
  #lightcv .portfolio-grid figure {
    width: 50%;
  }
  #lightcv .info-list {
    margin-top: 20px;
  }
  #lightcv .blog-post-content {
    padding: 0;
    margin: 30px 0 0;
  }
  #lightcv .blog-masonry.two-columns .item,
  #lightcv .blog-masonry.three-columns .item {
    width: 100%;
  }
  #lightcv .portfolio-grid figure,
  #lightcv .portfolio-grid.three-columns figure {
    width: 50%;
  }
  #lightcv .portfolio-grid.four-columns figure,
  #lightcv .portfolio-grid.five-columns figure {
    width: 33.3333333%;
  }
  #lightcv .page-portfolio-loaded .portfolio-page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  #lightcv .portfolio-page-carousel .owl-nav .owl-prev {
    margin-left: 0;
  }
  #lightcv .portfolio-page-carousel .owl-nav .owl-next {
    margin-right: 0;
  }
  #lightcv .portfolio-page-carousel .owl-nav .owl-prev,
  #lightcv .portfolio-page-carousel .owl-nav .owl-next {
    background-color: rgba(255, 255, 255, 0.55);
  }
  #lightcv .single-page-content .portfolio-page-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 480px) {
  #lightcv .section-title-block.first-style .section-description {
    margin: 7px 0 0;
    width: 100%;
  }
  #lightcv .portfolio-grid figure {
    width: 100%;
  }
  #lightcv .portfolio-grid.three-columns figure,
  #lightcv .portfolio-grid.two-columns figure {
    width: 100%;
  }
  #lightcv .portfolio-grid figure,
  #lightcv .portfolio-grid.two-columns figure,
  #lightcv .portfolio-grid.three-columns figure,
  #lightcv .portfolio-grid.four-columns figure,
  #lightcv .portfolio-grid.five-columns figure {
    width: 100%;
  }
  #lightcv .timeline-second-style .timeline-item {
    position: relative;
    display: block;
  }
  #lightcv .timeline-second-style .left-part {
    width: 100%;
    display: block;
    padding-left: 25px;
    min-height: 100%;
    text-align: left;
  }
  #lightcv .timeline-second-style .divider {
    left: 0;
  }
  #lightcv .timeline-second-style .right-part {
    width: 100%;
    display: block;
    padding-left: 25px;
    padding-right: 0;
  }
  #lightcv .single-page-content .post-content {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #lightcv .controls.two-columns .right-column,
  #lightcv .controls.two-columns .left-column {
    float: none;
    margin: 0;
    width: 100%;
  }
}
#lightcv .fw-pricing .fw-package {
  background-color: #fff;
  border: 2px solid #e5e5e5;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 10px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .fw-pricing .fw-package-wrap.highlight-col .fw-package {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.12);
  box-shadow: 0 7px 12px rgba(0, 0, 0, 0.12);
}
#lightcv .fw-pricing .fw-package:hover,
#lightcv .fw-pricing .fw-package-wrap.highlight-col .fw-package:hover {
  -webkit-transform: translateY(-9px);
  -ms-transform: translateY(-9px);
  -o-transform: translateY(-9px);
  transform: translateY(-9px);
  -webkit-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.15);
}
#lightcv .fw-pricing .fw-package-wrap.highlight-col .fw-heading-row span {
  position: relative;
  display: inline-block;
}
#lightcv .fw-pricing .fw-package-wrap.highlight-col .fw-heading-row span:after {
  content: "";
  height: 2px;
  margin-top: 0;
  background-color: #04b4e0;
  position: absolute;
  left: 0;
  bottom: -3px;
  right: 0;
}
#lightcv .fw-pricing .fw-pricing-row {
  padding: 30px 0 10px;
}
#lightcv .fw-pricing .fw-pricing-row span {
  display: block;
  line-height: 1;
  color: #333;
  font-size: 42px;
}
#lightcv .fw-pricing .fw-pricing-row small {
  color: #aaa;
  display: block;
}
#lightcv .fw-pricing .fw-default-row {
  padding: 5px 0;
  font-size: 14px;
}
#lightcv .fw-pricing .fw-button-row {
  padding: 10px 0 20px;
}
#lightcv .fw-pricing .fw-price-icon-no:before {
  content: "\f068";
  color: #e87878;
}
#lightcv .fw-pricing .fw-price-icon-yes:before {
  content: "\f058";
  color: #ade878;
}
#lightcv .fw-table table {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.875em;
}
#lightcv .fw-table table tr,
#lightcv .fw-table table tr.heading-row {
  border-bottom: 1px solid #eee;
}
#lightcv .fw-table table tr:not(.heading-row):last-child {
  border-bottom: 0;
}
#lightcv .fw-table table th {
  padding: 7px 12px;
  border-right: 1px solid #eee;
}
#lightcv .fw-table table td {
  padding: 7px 12px;
  border-right: 1px solid #eee;
}
#lightcv .fw-table table td:last-child {
  border-right: 0;
}
#lightcv .lm-animated-bg {
  position: absolute;
  width: auto;
  height: auto;
  top: -28px;
  left: -28px;
  right: -28px;
  bottom: -28px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
#lightcv .vcentered {
  vertical-align: middle;
  width: 100%;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
#lightcv .vcentered .row {
  width: 100%;
}
#lightcv .title-block {
  position: relative;
  text-align: center;
}
#lightcv .title-block h2 {
  font-size: 60px;
  line-height: 74px;
  margin: 0;
  text-align: center;
}
#lightcv .title-block .sp-subtitle {
  color: #888;
  font-size: 21px;
  font-weight: 300;
  margin: 5px 0;
  text-align: center;
}
#lightcv .text-rotation {
  display: block;
  width: 100%;
  position: relative;
}
#lightcv .start-page .mask {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(10, 10, 10, 0.45);
}
#lightcv .fun-fact {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 0 15px;
  background: #fff;
  padding: 25px 10px 15px;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#lightcv .fun-fact:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
}
#lightcv .fun-fact.gray-bg {
  background-color: #fcfcfc;
}
#lightcv .fun-fact h4 {
  font-size: 16px;
  margin: 15px;
}
#lightcv .fun-fact i {
  position: relative;
  font-size: 33px;
  color: #04b4e0;
  margin-bottom: 6px;
  width: 33px;
  height: 33px;
}
#lightcv .fun-fact .lm-info-block {
  font-size: 24px;
  font-weight: 400;
  display: block;
  margin: 10px 0;
  color: #04b4e0;
}
#lightcv .fun-fact-block-value {
  color: #aaa;
  font-size: 36px;
  line-height: 50px;
  display: block;
  margin: 15px 0 10px;
}
#lightcv .fun-fact-block-text {
  display: block;
}
@media only screen and (max-width: 1024px) {
  #lightcv .lm-animated-bg {
    display: none;
  }
  #lightcv .title-block h2 {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 10px;
    text-align: center;
  }
  #lightcv .title-block .sp-subtitle {
    font-size: 18px;
  }
}
#lightcv .lmpixels-map iframe {
  height: 140px;
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}




#lightcv .about_content{
    font-size: 15px;
    font-weight: 300;
    font-family: poppins, Helvetica, sans-serif;
    color: #808080
}

#lightcv .value{
  color: #706f6f!important;
  font-size: 14px;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 400;
}
#lightcv .ci-text p{
  color: #333131!important;
  font-size: 14px;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 400;
}
#lightcv .text p{
  color: #333131!important;
  font-size: 13px;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 500;
}

#lightcv .fw-heading-row span{
  color: #333131!important;
  font-size: 13px;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 500;    
}

#lightcv .fw-default-row{
  color: #333131!important;
  font-size: 13px;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 500;    
}

#lightcv .free_trial{
  padding: 10px 30px;
  background-color: #fff;
  border: 2px solid #a3a3a3;
  border-radius: 100px;
  color: #333131;
  font-weight: 400;
  font-family: poppins, Helvetica, sans-serif;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.item-company{
  color: #333131!important;
  font-size: 14px!important;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 500;    
}

#lightcv .timeline {
  margin-top: 1rem;
  padding-left: 1.5rem;
  position: relative;
}
#lightcv .right-part p{
  color: #333131!important;
  font-size: 13px!important;
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 400; 
}

#lightcv .knowledges li {
  display: inline-block!important;
  background-color: #04b4e0!important;
  border-radius: 3px!important;
  color: #fff!important;
  padding: 3px 10px!important;
  margin: 3px 2px!important;
  font-size: 13px!important;
  font-weight: 600;
  -webkit-border-radius: 3px!important;
  -moz-border-radius: 3px!important;
  -ms-border-radius: 3px!important;
  -o-border-radius: 3px!important;
}


.timeline::after {
  border: none;
}
.timeline::before {
  border: none;
}

/* light theme css end */


.portfolioContainer img, video{
height: 150px;
width: 100%;
}

#dark_theme video{
  width: 100%;
  height: 180px!important;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

#dark_theme iframe{
  width: 100%;
  height: 180px!important;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

#lightcv iframe{
  width: 100%;
  height: 180px!important;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

#lightcv video{
  width: 100%;
  height: 180px!important;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

#contactMessage{
  display: none;
  z-index: 4444;
  position: fixed;
  top: 10px;
  right: 10px;
}
#contactMessage #alertMessage{
  /* display: none; */
  background-color: rgba(0, 128, 0, 0.589);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}

.dark_theme .owl-theme .owl-nav [class*='owl-'] {
  display: none;
}
.dark_theme .owl-theme button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}
.dark_theme .owl-theme .owl-dots{
  text-align: center;
}
.dark_theme .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.dark_theme .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #f8c323;
}
#lightcv .page-body-wrapper.full-page-wrapper .main-panel .content-wrapper div{
  height: auto!important;
}