.Project{
    border: 1px solid #e5e5e5;
    background-color: #fff;
}
.Project_title{
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}

#messageAlertLanguage{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}