.resume-title{
    font-weight: 500;
}
.profile_image img{
    height: 100px;
    width: 100px;
    border-radius: 100px;
}
.profile_detail h4{
    padding-top: 6%;
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
.profile_detail span{
    font-size: 15px;
    color: #89759e;
}
.profile_detail .avialable{
    font-size: 14px;
    padding-top: 3px;
    color: rgb(238, 85, 85);
}
.my_website a{
    text-decoration: none;
    color: rgb(238, 85, 85);
    font-size: 14px;
    text-align: center;
}
.address span{
    font-size: 15px;
}
.account_tab{
    width: 100%;
    float: left;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.nav-tabs .nav-link:hover{
    background-color: rgb(255, 255, 255);
    color: #ff4500!important;
    border: 1px solid rgb(211, 211, 211);
    border-bottom: none;
}

.account_tab .nav-link {
    display: block;
    padding: 10px 12px!important;
    color: #242424;
    font-size: 13px;
    font-family: roboto,sans-serif;
    font-weight: 400;
}
.nav-tabs .active {
    background-color: rgb(255, 255, 255);
    color: #ff4500!important;
    border: 1px solid rgb(211, 211, 211);
    border-bottom: none;
}


.error{
    color: red;
    font-size: 14px;
}

#messageAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
.messageDanger{
    color: white;
    padding: 10px 5px;
    background-color: red;
    z-index: 5555!important;
}
.messageSuccess{
    color: white;
    padding: 10px 5px;
    background-color: rgba(0, 128, 0, 0.7);
}



.job_nav {
    flex-direction: column;
    background: #fff;
    border: none!important;
    cursor: pointer;
}
.job_nav .nav-tabs{
    border: none!important;
}
.job_nav .nav-link{
    border-left: 2px solid transparent!important;
}
.job_nav .nav-link:hover {
    color: #ff4500!important;
    background-color: #ffffff!important;
    border-left: 2px solid #ff4500!important;
    border-top: none!important;
    border-right: none!important;
    border-bottom:none!important;
    margin-bottom: 0!important;
}
.job_nav .nav-item {
    margin-bottom: 0!important;
}
.job_nav .nav-link{
    text-align: left;
}
.job_nav .activeResumeLeftMenu{
    border-left: 2px solid #ff4500!important;
    color: #ff4500!important;
}
.job_nav .activeResumeLeftMenu .text-dark{
    color: #ff4500!important;
}





/* profile css */
.profile{
    border: 1px solid #e5e5e5;
}
.profile_title{
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}
.profile1_image span{
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
}
.profile1_image img{
    height: 170px;
    width: 170px;
    border-radius: 100px;
}

.description_actions {
    padding: 8px 6px;
    border: 1px solid #e5e5e5;
}
.description_actions a{
    font-size: 15px;
    padding-left: 20px;
    font-weight: bold;
    color: rgb(90, 89, 89);
    text-decoration: none;
}
.description_actions a:hover{
    color: black;
}

.post_jp_btn {
    font-size: 14px;
    font-weight: 500;
    font-family: roboto,sans-serif;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #ff4500;
    padding: 0 20px;
    height: 50px;
    border: 0;
    margin: 15px 0 0;
}



/* social link section */

.social_account{
    border: 1px solid #e5e5e5;
}
.social_title{
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}


.change_password{
    border: 1px solid #e5e5e5;
    background-color: #fff;
}
.password_title{
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}
.password_section{
    width: 90%;
    background-color: #ececec;
    margin: auto;
    border: 1px solid #e5e5e5;
    padding-top: 10px;
}
.password_disc li{
    font-size: 13px;
    font-weight: 550;
}
.profile .uplaod_image{
    border: none;
    outline: none;
}


/* text editor css */

.custom-css-class{
    border: 1px solid red;
}
.EditorToolbar__root___3_Aqz {
    font-family: Helvetica,sans-serif;
    font-size: 10px;
    margin: 0px!important;
    padding-left: 10px!important;
    user-select: none;
}
.RichTextEditor__editor___1QqIU {
    cursor: text;
    font-size: 10px;
    height: 80px!important;
}
