input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.forgot h2{
    font-size: 22px;
    font-weight: 400;
    margin-top: 20%;
}
.forgot p{
    font-size: 13px;
    line-height: 16px;
    color: #8a8888;
    font-family: Arial, Helvetica, sans-serif;
}
.custom-form {
    margin-top: -30px;
    height: 40px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e1;
    outline: none;
}
.custom-form::placeholder{
    font-size: 14px;
}
.custom-form:focus{
    border-bottom: 1px solid #053cee;
    transition: 1s all;
    -webkit-transition: 1s all;
    -moz-transition: 1s all;
    -ms-transition: 1s all;
    -o-transition: 1s all;
}
.send_btn{
    margin-top: 20px;
    float: right;
    border: none;
    outline: none;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(72, 119, 221) 0%, rgba(20,84,194,1) 62%, rgb(6, 78, 129) 100%, rgba(0,212,255,1) 100%);  transition: all 0.3s ease-in-out;
    padding: 7px 25px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.send_btn i{
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}


.varification h2{
    font-size: 22px;
    font-weight: 400;
    margin-top: 20%;
}
.varification p{
    font-size: 13px;
    line-height: 16px;
    color: #8a8888;
    font-family: Arial, Helvetica, sans-serif;
}
.varification .form-control{
    height: 35px;
}
.varification h6{
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 500;
    color: #808080;
}
.otp_btn{
    margin-top: 20px;
    float: right;
    border: none;
    outline: none;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(72, 119, 221) 0%, rgba(20,84,194,1) 62%, rgb(6, 78, 129) 100%, rgba(0,212,255,1) 100%);  transition: all 0.3s ease-in-out;
    padding: 7px 25px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.otp_btn i{
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}
.resend_OTP{
    padding-top: 27px;
    float: left;
    text-decoration: none;
    font-size: 14px;
}