#conversation {
  height: 360px;
  overflow-y: scroll;
  background-color: #fff;
}
#conversation::-webkit-scrollbar {
  width: 0px;
}
.message {
  padding: 0 !important;
  margin: 0 !important;
  overflow-y: auto;
  height: calc(100% - 100px);
}
.send_msg {
  height: 50px;
  background-color: #fff;
  line-height: 50px;
}
.send_msg textarea {
  margin-top: 7px;
  background-color: #e6ebf5;
  border: none;
  padding: 12px;
  border-radius: 5px;
}
.send_msg textarea:focus {
  background-color: #e6ebf5;
}
.send_msg .send_icon i {
  background-color: #7269ef;
  padding: 5px 10px;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  /*padding: 10px 20px;*/
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  float: right;
  max-width: 100%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.receiver {
  margin-top: 8px;
  width: 65%;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #f3f3f3;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  margin-top: 3px;
  width: 45%;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  float: right;
  word-wrap: break-word;
}

@media screen and (max-width: 700px) {
  .message-main-receiver {
    /*padding: 10px 20px;*/
    max-width: 100%;
  }

  .message-main-sender {
    padding: 3px 20px !important;
    max-width: 100%;
  }
  .receiver {
    margin-top: 8px;
    width: 70%;
  }

  .sender {
    margin-top: 3px;
    width: 80%;
    float: right;
  }
}
