.Protofolio{
    border: 1px solid #e5e5e5;
    background-color: #fff;
}
.Protofolio_title{
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}
.add_protofolio{
    font-size: 15px;
    line-height: 45px;
    font-weight: 500;
    font-family: roboto,sans-serif;
    background: #ff4500;
    color: #fff;
    border: 0;
    border-radius: 3px;
    height: 45px;
    width: 150px;
    padding: 0 20px;
    float: right;
}
.Protofolio .uplaod_image{
    border: none;
    outline: none;
}
.Protofolio .upload_size{
    font-size: 14px;
    color: #818181;
}


#messageAlertPortfolio{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}