.resumesec{
    border: 1px solid #e5e5e5;
    background-color: #fff;
}
.resumesec_title{
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}
.resumesec .file{
    font-size: 35px;
    margin: auto;
}
.upload_section a{
    text-decoration: none;
    margin-left: 4px;
}
.upload_section .upload_file{
    margin-left: 30%;
    margin-top: 5%;
}



@media only screen and (max-width: 600px) {
    .upload_section .upload_file{
        margin-left: 5%;
        margin-top: 3%;
    }
}


/* #messageAlertCV{
    position: fixed;
    top: 30px;
    right: 5px;
    display: block;
    z-index: 4444;
} */

#messageCV{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}