.jobViewSection .totalCount{
    background-color: powderblue;
    padding: 7px 12px;
    font-size: 15px;
    color: #5164F3;
}
#jobAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
#createJobAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}

.job_apply{
    border: none;
    outline: none;
    padding: 5px 20px;
    background-color: #5164F3;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.rupee_sign{
    font-weight: 600;
}

.job_description label{
    font-size: 14px;
    color: #8d8d8d;
}
.desc{
    padding-top: 2px;
    padding-bottom: 5px;
    font-size: 15px;
    color: #8d8d8d;
}